<template>
  <div>
    <b-overlay
      :show="isLoading || isValidating || isCheckout || isApplyingVoucher"
      rounded="sm"
    >
      <form-wizard
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        @on-complete="onSubmit"
      >
        <!-- account detail tab -->
        <tab-content
          :title="$t('order_details')"
          icon="feather icon-file-text"
          :before-change="validateFirstSteps"
        >
          <ValidationObserver ref="deliveryDetails" v-slot="{ passes, errors }">
            <form @submit.prevent="passes(validateFirstSteps)">
              <b-row class="mb-2">
                <b-col cols="12">
                  <b-row class="mb-1">
                    <b-col sm="6" md="5" lg="6">
                      <DeliveryType
                        :id="'self-delivery-option'"
                        :value="1"
                        :title="$t('self_delivery')"
                        :description="$t('self_delivery_description')"
                        :form="form"
                        @setPickUpAddresses="handleSetPickupAddresses"
                      />
                    </b-col>
                    <b-col
                      sm="6"
                      md="5"
                      offset-md="2"
                      lg="6"
                      offset-lg="0"
                      class="d-flex"
                    >
                      <DeliveryType
                        :id="'smarttruck-delivery-option'"
                        :value="0"
                        :title="$t('smarttruck_delivery')"
                        :description="$t('smarttruck_delivery_description')"
                        :form="form"
                        @setPickUpAddresses="handleSetPickupAddresses"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <PickUpAddressAndDeliveryDate
                :form="form"
                :pick-up-addresses="pickupAddresses"
                @setPickupId="(data) => handleUpdateFormData(data)"
              />
              <hr />

              <b-row v-if="orders.length > 0">
                <b-col md="12" style="overflow-y: auto; max-height: 750px">
                  <app-collapse :accordion="true" type="margin" class="mb-1">
                    <app-collapse-item
                      v-for="(order, index) in orders"
                      :key="index"
                      :is-visible="true"
                      title=""
                    >
                      <template #header>
                        <h4 class="text-primary mb-0">
                          {{ `Order ${index + 1}` }}
                        </h4>
                        <div>
                          <span v-if="hasErrors(errors, index)" class="mr-2">
                            <feather-icon
                              icon="AlertTriangleIcon"
                              size="20"
                              class="text-primary"
                            />
                          </span>

                          <span
                            v-if="
                              !hasErrors(errors, index) &&
                              validatedIndex.includes(index) &&
                              !isValidating
                            "
                            class="mr-2"
                          >
                            <feather-icon
                              icon="CheckCircleIcon"
                              size="20"
                              class="text-success"
                            />
                          </span>

                          <b-button
                            v-if="index > 1"
                            variant="flat-primary"
                            class="btn-icon mr-2"
                            @click.prevent="handleRemoveOrder(index)"
                          >
                            <feather-icon icon="TrashIcon" size="20" />
                          </b-button>
                        </div>
                      </template>
                      <TypeOfMarketAndClient
                        :markets="order.fields.markets"
                        :form="order"
                        :recipients="order.fields.recipients"
                        :outlets="order.fields.outlets"
                        :type-of-order="'bulk order'"
                        :index="index"
                        @setRecipients="handleSetRecipients(index)"
                        @setOutlets="handleSetOutlets(index)"
                        @calculatePrice="calculateCharges(index)"
                      />
                      <hr />
                      <ProductDetails
                        :order="order"
                        :categories="order.fields.categories"
                        :packaging="order.fields.packaging"
                        :remarks="order.fields.remarks"
                        :index="index"
                        :type-of-order="'bulk order'"
                        @calculatePrice="calculateCharges(index)"
                      />
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
          <b-row>
            <b-col md="12" class="mt-3">
              <b-button
                type="button"
                variant="primary"
                @click.prevent="handleAddOrder"
              >
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="align-middle">Add Order</span>
              </b-button>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content :title="$t('checkout')" icon="feather icon-shopping-cart">
          <BulkCheckoutOrder
            :items="getItemsTable"
            :vouchers="vouchers"
            :order="form"
            :overall-charges="overallCharges"
            @applyVoucherDiscount="handleApplyVoucherDiscount"
          />
        </tab-content>
        <tab-content :title="'Payment Method'" icon="feather icon-dollar-sign">
          <div>
            <PaymentMethod @payment_method="handleSetPaymentMethod" />
          </div>
        </tab-content>
        <b-button
          slot="next"
          class="wizard-btn"
          variant="primary"
          :disabled="orders.length <= 0"
        >
          {{ $t('checkout') }}
        </b-button>
        <b-button
          slot="finish"
          class="wizard-btn"
          variant="primary"
          :disabled="isCheckout"
        >
          <b-spinner v-if="isCheckout" class="mr-50" small />
          {{ $t('proceed_to_payment') }}
        </b-button>
      </form-wizard>
      <template #overlay>
        <div class="text-center">
          <b-spinner medium variant="primary" />
          <p id="cancel-label" class="text-primary">
            {{ loadingMessage }}
          </p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  DeliveryType,
  BulkCheckoutOrder,
  ProductDetails,
  TypeOfMarketAndClient,
  PickUpAddressAndDeliveryDate
} from '@/components/CreateOrderFields'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters, mapState } from 'vuex'
import axios from '@axios'
import { BRow, BCol } from 'bootstrap-vue'
import { PaymentMethod } from '@/components'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    ValidationObserver,
    BulkCheckoutOrder,
    DeliveryType,
    ProductDetails,
    TypeOfMarketAndClient,
    PickUpAddressAndDeliveryDate,
    PaymentMethod
  },
  data() {
    return {
      selectedPackage: '',
      priceIsNotSet: false,
      isCheckout: false,
      isValidating: false,
      isApplyingVoucher: false,
      selectedVoucher: '',
      vouchers: [],
      validatedIndex: [],
      mop: ''
    }
  },
  computed: {
    ...mapGetters({
      form: 'bulkDelivery/form',
      pickupAddresses: 'bulkDelivery/pickupAddresses',
      categories: 'bulkDelivery/categories',
      packaging: 'bulkDelivery/packaging',
      remarks: 'bulkDelivery/remarks',
      markets: 'bulkDelivery/markets',
      isLoading: 'bulkDelivery/isLoading',
      overallCharges: 'bulkDelivery/overallCharges'
    }),
    ...mapState({
      orders: (state) => state.bulkDelivery.orders
    }),
    getItemsTable() {
      return this.orders.map((order) => ({
        outlet_address: order.outlet_address,
        sender_address: this.form.sender_address,
        is_pickup: this.form.is_pickup,
        delivery_date: this.form.delivery_date,
        category_name: order.fields.categories.find(
          (category) => category.value === order.category_id
        )?.text,
        qty: order.qty,
        pickup_charge: order.pickup_charge,
        amount: order.amount,
        unit_price: order.unit_price
      }))
    },
    getFinalForm() {
      return this.orders.map((order) => ({
        pickup_id: this.form.sender_address
          ? this.form.sender_address.id
          : null,
        client_id: order.recipient ? order.recipient.id : null,
        dropoff_id: order.outlet_address ? order.outlet_address.id : null,
        outlet_address: order.outlet_address,
        sender_address: this.form.sender_address,
        is_pickup: this.form.is_pickup,
        delivery_date: this.form.delivery_date,
        category_id: order.category_id,
        category_name: order.fields.categories.find(
          (category) => category.value === order.category_id
        )?.text,
        qty: order.qty,
        sku: order.sku,
        size: order.size,
        unit: order.unit,
        unit_price: order.unit_price,
        temperature: order.temperature,
        remarks:
          order.selectedRemarks === 'Others'
            ? order.custom_remarks
            : order.selectedRemarks,
        packaging:
          order.selectedPackaging === 'Others'
            ? order.custom_package
            : order.selectedPackaging,
        weight: order.weight,
        po_expiry_date: order.po_expiry_date,
        po_number: order.po_number,
        invoice_number: order.invoice_number,
        price: order.price,
        golog_cut: order.golog_cut,
        smarttruck_cut: order.smarttruck_cut,
        pickup_charge: order.pickup_charge,
        tax_charge: order.tax_charge,
        type_of_market: order.type_of_market,
        voucher: this.form.selectedVoucher,
        is_over_size: this.$validate.isSizeOnDoubleCharge(
          order.size,
          order.unit
        ),
        is_over_weight: this.$validate.isWeightOnDoubleCharge(order.weight)
      }))
    },
    loadingMessage() {
      if (this.isLoading) {
        return 'Fetching fields data...'
      }

      if (this.isValidating) {
        return 'Validating Form, Please wait...'
      }

      if (this.isCheckout) {
        return 'Processing Order...'
      }

      if (this.isApplyingVoucher && this.form.selectedVoucher) {
        return 'Applying Voucher Discount...'
      }
      return 'Removing Voucher Discount...'
    }
  },
  async created() {
    await this.$store.dispatch('bulkDelivery/fetchFieldsValue')
    this.fetchUserVoucher()
    if (this.orders.length <= 0) {
      this.handleAddOrder()
    }
  },
  methods: {
    handleAddOrder() {
      if (this.orders.length < 50) {
        this.$store.dispatch('bulkDelivery/addOrder', {
          form: this.defaultOrderForm()
        })
      }
    },
    async onSubmit() {
      try {
        this.isCheckout = true
        const response = await axios.post('bulk-orders', {
          orders: this.getFinalForm,
          step: 2,
          delivery_date: this.form.delivery_date,
          pickup_id: this.form.sender_address.id,
          total_charge: this.overallCharges.grandTotal,
          voucher: this.form.selectedVoucher,
          payment_method: this.mop
        })
        if (response.data) {
          if (this.mop === 'SENANGPAY') {
            window.location.href = response.data.response
          } else {
            if (response.data.status) {
              window.location.href = `/delivery-confirmed/${response.data.reference_number}/${this.mop}`
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    'Not Enough Logpoint. Please reload or choose other payment methods.',
                  icon: 'WarningIcon',
                  variant: 'warning'
                }
              })
            }
            this.isCheckout = false
          }
        }
        this.isCheckout = false
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
    handleSetPickupAddresses(value) {
      this.handleUpdateFormData({ is_pickup: value })
      this.$store.dispatch('bulkDelivery/setPickupAddresses')
    },
    async validateFirstSteps() {
      this.isValidating = true
      this.getFinalForm.forEach((value, index) => {
        if (!this.validatedIndex.includes(index)) {
          this.validatedIndex.push(index)
        }
      })
      const response = await axios.post('bulk-orders', {
        orders: this.getFinalForm,
        step: 1,
        delivery_date: this.form.delivery_date,
        pickup_id: this.form.sender_address
      })
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          this.isValidating = false
          resolve(true)
        }
        if (response.status === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please fill in the required fields.',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })

          this.$refs.deliveryDetails.setErrors(response.data.errors)
        }
        this.isValidating = false
        reject()
      })
    },
    handleSetPaymentMethod(value) {
      this.mop = value
    },
    handleSetRecipients(index) {
      this.$store.dispatch('bulkDelivery/setRecipients', { index })
    },
    handleSetOutlets(index) {
      this.$store.dispatch('bulkDelivery/setOutlets', { index })
    },
    handleUpdateFormData(data) {
      this.$store.dispatch('bulkDelivery/resetOutletAddresses')
      this.$store.dispatch('bulkDelivery/updateFormData', { data })
    },
    async calculateCharges(index) {
      const current = this.getFinalForm[index]
      if (current.sender_address && current.outlet_address) {
        const response = await axios.get(
          `prices/price-coverage?states=${current.sender_address.state},${current.outlet_address.state}`
        )
        if (response.status !== 200) {
          this.$swal({
            title: 'Contact Customer Service',
            text: 'Oops! Price is not set for these states, kindly proceed to contact with our Customer Service representative at this hotline 019-8196692. Thank you!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#ea5455'
          })
          this.$store.dispatch('bulkDelivery/updateOrderForm', {
            data: { outlet_address: null, dropoff_id: null },
            index
          })
          return
        }
        this.$store.dispatch('bulkDelivery/calculateCharges', {
          order: current,
          index
        })
      }
    },
    handleRemoveOrder(index) {
      this.$swal({
        title: 'Are you sure you want to remove this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('bulkDelivery/removeOrder', { index })
        }
      })
    },
    async fetchUserVoucher() {
      const response = await axios.get('/user-vouchers')
      if (response.status === 200) {
        this.vouchers = response.data
      }
    },
    async handleApplyVoucherDiscount() {
      this.isApplyingVoucher = true
      await this.$store.dispatch('bulkDelivery/applyVoucherDiscount')
      this.isApplyingVoucher = false
    },
    hasErrors(errors, index) {
      const errorIndexes = Object.keys(errors)
        .map((key) => {
          const temp = key
          if (temp.includes('orders.') && errors[key].length > 0) {
            return temp.charAt(7)
          }
          return false
        })
        .filter((x) => x)
        .filter((v, i, a) => a.indexOf(v) === i)
      return errorIndexes.includes(`${index}`)
    },
    defaultOrderForm() {
      return {
        client_id: null,
        category_id: null,
        pickup_id: null,
        dropoff_id: null,
        category_name: null,
        qty: null,
        sku: null,
        size: null,
        unit: 'cm',
        price: null,
        golog_cut: null,
        smarttruck_cut: null,
        pickup_charge: null,
        tax_charge: null,
        temperature: null,
        amount: null,
        remarks: null,
        packaging: null,
        selectedPackaging: null,
        selectedRemarks: null,
        weight: null,
        is_pickup: null,
        delivery_date: null,
        po_expiry_date: null,
        po_number: null,
        invoice_number: null,
        is_over_size: 0,
        is_over_weight: 0,
        sender_address: null,
        outlet_address: null,
        type_of_market: null,
        recipient: null,
        custom_package: null,
        custom_remarks: null,
        length: null,
        width: null,
        height: null,
        selectedVoucher: null,
        unit_price: null,
        fields: {
          categories: this.categories,
          packaging: this.packaging,
          remarks: this.remarks,
          markets: this.markets,
          recipients: [],
          outlets: []
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
